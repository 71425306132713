import React from 'react';
import styled from 'styled-components';
// import AdButler from '../../components/ThankyouPages/AdButler';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import storage from '../../utils/storage';
import CongratsSvg from '../../components/ThankyouPages/congratsSvg';

// prettier-ignore
// const adIds = ['361007', '361008', '361009', '368089', '368090', '368091', '368092', '368093'];

const EmmContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
  
  font-family: 'Roboto', sans-serif;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
`
const IntroBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  .logoContainer {
    width: 100%;
    max-width: 300px;
  }
  p {
    color: #000;
    font-size: clamp(30px, 5.5vw, 60px);
    font-weight: 700;
    margin: 0;
    a {
      color: #000;
      text-decoration: none;
    }
  }
  @media (max-width: 801px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const CongratsBlock = styled.div`
  background: #00326d;
  color: #fff;
  padding: 20px 10px;
  p {
    text-align: center;
    font-size: clamp(30px, 5.5vw, 60px);
    font-weight: 700;
    margin: 0;
  }
`;

const ClosedInBlock = styled.div`
  width: 100%;
  padding: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .svgBlock {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .textBlock {
    width: 38%;
    p {
      font-size: clamp(18px, 2vw, 22px);
      font-weight: 700;
      text-align: center;
      margin: 0;
    }
  }
  @media (max-width: 801px) {
    flex-wrap: wrap;
    .svgBlock,
    .textBlock {
      width: 100%;
    }
    .svgBlock {
      flex-wrap: wrap;
      padding: 0 15px;
    }
  }
`;

const Divider = styled.div`
  width: 90%;
  margin: 0px auto;
  .divider {
    stroke: #7a7a7a;
  }
`;

const FinalBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .bullets,
  .review {
    width: 100%;
  }
  .bullet {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    p {
      margin: 0;
      font-size: clamp(24px, 2.5vw, 30px);
      font-weight: 700;
      span {
        font-size: clamp(18px, 2vw, 22px);
      }
    }
  }
  .checkmark {
    width: 60px;
    margin: 0 20px;
  }
  .review {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .reviewer {
    font-size: clamp(28px, 3.5vw, 46px);
    font-weight: 700;
    letter-spacing: 0.04em;
    margin: 0;
  }
  .reviewerRating {
    display: flex;
    align-items: flex-end;
    margin-bottom: 12px;
    .stars {
      width: 30%;
    }
    p {
      font-size: clamp(18px, 2vw, 22px);
      margin: 0 0 0 20px;
      color: #7a7a7a;
    }
  }
  .star {
    fill: #fbc509;
  }
  .reviewerComment {
    font-size: clamp(18px, 2vw, 22px);
  }

  @media (max-width: 801px) {
    flex-wrap: wrap;
  }
`;

const EmmThankyou = () => (
  <EmmContainer>
    <IntroBlock>
      <div className="logoContainer">
        <img
          src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/MadisonMortgageLogo.png"
          alt="Madison Mortgage Logo"
        />
      </div>
      <p>
        Call Now! <a href="tel:8559476219">855-947-6219</a>
      </p>
    </IntroBlock>
    <CongratsBlock>
      <p>Congratulations! We've Found a Match!</p>
    </CongratsBlock>
    <ClosedInBlock>
      <div className="svgBlock">
        <CongratsSvg color="green" purpose="purchase" days="5" />
        <CongratsSvg color="orange" purpose="refi" days="4" />
        <CongratsSvg color="green" purpose="purchase" days="13" />
        <CongratsSvg color="orange" purpose="refi" days="3" />
      </div>
      <div className="textBlock">
        <p>
          Today is the perfect day to purchase your new home or refinance an
          existing one! We make this process as smooth and fast as possible so
          you can spend less time on paperwork, and more time in your home.
        </p>
      </div>
    </ClosedInBlock>
    <Divider>
      <svg id="divider" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 6">
        <line className="divider" x1="3" y1="3" x2="97" y2="3" />
      </svg>
    </Divider>
    <FinalBlock>
      <div className="bullets">
        <div className="bullet">
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/CircleWithCheck.png"
            alt="Checkmark"
            className="checkmark"
          />
          <p>Amazing Customer Service</p>
        </div>
        <div className="bullet">
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/CircleWithCheck.png"
            alt="Checkmark"
            className="checkmark"
          />
          <p>FAST and EFFICIENT Process</p>
        </div>
        <div className="bullet">
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/CircleWithCheck.png"
            alt="Checkmark"
            className="checkmark"
          />
          <p>Historically Low Interest Rates</p>
        </div>
        <div className="bullet">
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/CircleWithCheck.png"
            alt="Checkmark"
            className="checkmark"
          />
          <p>
            NMLS #1862796 <span>www.madisonmortgage.com</span>
          </p>
        </div>
      </div>
      <div className="review">
        <div className="reviewerImage">
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/MarioV.png"
            alt="Mario V"
          />
        </div>
        <div className="reviewContent">
          <p className="reviewer">Mario V.</p>
          <div className="reviewerRating">
            <svg
              id="stars"
              className="stars"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 250 44.41"
            >
              <polygon
                className="star"
                points="37.64 44.41 23.26 36.82 8.89 44.4 11.63 28.34 0 16.96 16.07 14.62 23.26 0 30.45 14.62 46.52 16.96 34.89 28.34 37.64 44.41"
              />
              <polygon
                className="star"
                points="241.12 44.41 226.74 36.82 212.37 44.4 215.11 28.34 203.48 16.96 219.55 14.62 226.74 0 233.93 14.62 250 16.96 238.37 28.34 241.12 44.41"
              />
              <polygon
                className="star"
                points="88.51 44.41 74.13 36.82 59.76 44.4 62.5 28.34 50.87 16.96 66.94 14.62 74.13 0 81.32 14.62 97.39 16.96 85.76 28.34 88.51 44.41"
              />
              <polygon
                className="star"
                points="139.38 44.41 125 36.82 110.63 44.4 113.37 28.34 101.74 16.96 117.81 14.62 125 0 132.19 14.62 148.26 16.96 136.63 28.34 139.38 44.41"
              />
              <polygon
                className="star"
                points="190.25 44.41 175.87 36.82 161.5 44.4 164.24 28.34 152.61 16.96 168.68 14.62 175.87 0 183.06 14.62 199.13 16.96 187.5 28.34 190.25 44.41"
              />
            </svg>
            <p>24 hours ago</p>
          </div>
          <p className="reviewerComment">
            Refinancing with Madison Mortgage was almost effortless. To think we
            almost bypassed this company over the saying “if it's too good to be
            true than it's not.” The process was unbelievably fast and painless.
            The closing was even done at my dining room table. Thank you for the
            help! We are definitely happy.
          </p>
        </div>
      </div>
    </FinalBlock>
  </EmmContainer>
);

export default withThankyouWrapper(EmmThankyou);
